<template>
  <div style="margin-top: 20px;">
    <a-row type="flex" justify="center">
      <a-col :span="20">
        <div style="height: 85vh">
          <a-row style="height: 100%">
            <!--用户名或登录-->
            <a-row>
              <div style="color: white;display: flex">
                <div v-if="loggedIn" style="font-size: 30px">{{ nickname }}</div>
                <div v-else>
                  <el-link @click="navigateToAuth('login')" type="primary" style="font-size: 30px" :underline="false">登入 BattlefieldTools</el-link>
                </div>
                <div style="font-size: 20px;margin-left: 20px;margin-top: 7px">
                  <i @click="queryIndexData" style="cursor: pointer;" :class="loading ? 'el-icon-loading' : 'el-icon-refresh-right'"></i>
                </div>
              </div>
            </a-row>
            <!--卡片栏-->
            <a-row style="margin-top: 20px" :gutter="20">
              <a-col :span="8">
                <div class="index-card-container">
                  <div class="index-card" @click="navigateToServer">
                    <div>
                      <el-image style="height: 300px;" src="https://pic.imgdb.cn/item/66e6b3e5d9c307b7e99ae8fb.jpg"/>
                    </div>
                    <div style="height: 80px;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;font-size: 17px;display: flex;flex-direction: column;justify-content: space-between">
                      <a-row>
                        已有 {{ enabledServerCount }} 个开启的服务器
                      </a-row>
                      <a-row>
                        {{ inGamePersonaCount }} 名玩家正在游戏中
                      </a-row>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="4">
                <div class="index-card-container">
                  <div class="index-card" @click="navigateToServer">
                    <div>
                      <el-image fit="cover" style="height: 300px;" src="https://pic.imgdb.cn/item/66e6b3ffd9c307b7e99afd6a.jpg"/>
                    </div>
                    <div style="height: 80px;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;font-size: 17px;display: flex;flex-direction: column;justify-content: space-between">
                      <a-row>
                        <a-col :span="12">
                          亚服 {{ asiaServerCount }} 个
                        </a-col>
                        <a-col :span="12">
                          欧服 {{ euServerCount }} 个
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="12">
                          美服 {{ usServerCount }} 个
                        </a-col>
                        <a-col :span="12">
                          其他 {{ otherRegionServerCount }} 个
                        </a-col>
                      </a-row>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="4">
                <div class="index-card-container">
                  <div class="index-card" @click="navigateToServer">
                    <div>
                      <el-image fit="cover" style="height: 300px;" src="https://pic.imgdb.cn/item/66e6f14fd9c307b7e9f00399.jpg"/>
                    </div>
                    <div style="height: 80px;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;font-size: 17px;display: flex;flex-direction: column;justify-content: space-between">
                      <a-row>
                        <a-col :span="12">
                          征服 {{ conquestServerCount }} 个
                        </a-col>
                        <a-col :span="12">
                          行动 {{ breakthroughLargeServerCount }} 个
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="12">
                          其他 {{ otherModeServerCount }} 个
                        </a-col>
                      </a-row>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="4">
                <div style="background-color: rgba(17,17,17,0.62)">
                  <div style="color: white;height: 385px;padding: 10px;display: flex;flex-direction: column;justify-content: space-between">
                    <div id="playerMapTreeMap" style="width: 100%;height: 100%"></div>
                  </div>
                </div>
              </a-col>
              <a-col :span="4">
                <div style="background-color: rgba(17,17,17,0.62)">
                  <div style="color: white;height: 385px;padding: 10px;display: flex;flex-direction: column;justify-content: space-between">
                    <div id="playerModeTreeMap" style="width: 100%;height: 100%"></div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {getCookie, getToken} from "../../utils/local-storage";
import {queryIndexDataApi} from "../../request/api";
import {Bf1Map, BF1Mode, BF1Region} from "../../enum/bf1-enum";

export default {
  name: "index",
  data() {
    return {
      loggedIn: false,
      nickname: '',
      enabledServerCount: 0,
      inGamePersonaCount: 0,
      asiaServerCount: 0,
      euServerCount: 0,
      usServerCount: 0,
      otherRegionServerCount: 0,
      conquestServerCount: 0,
      breakthroughLargeServerCount: 0,
      otherModeServerCount: 0,
      serverData: [],
      personaData: [],
      playerMapTreeMapData: [],
      playerModeTreeMapData: [],
      card1Activated: false,
      card2Activated: false,
      card3Activated: false,
      loading: false,
    }
  },
  created() {
    this.$emit('changeKey', '1')
    this.loggedIn = null !== getToken();
    if (this.loggedIn) {
      this.nickname = getCookie("nickname")
    }
    this.queryIndexData()
  },
  methods: {
    navigateToServer() {
      this.$router.push({path: "/server"});
    },
    queryIndexData() {
      if (this.loading) {
        return
      }
      this.loading = true;
      queryIndexDataApi().then((res) => {
        this.enabledServerCount = 0
        this.inGamePersonaCount = 0
        this.asiaServerCount = 0
        this.euServerCount = 0
        this.usServerCount = 0
        this.otherRegionServerCount = 0
        this.conquestServerCount = 0
        this.breakthroughLargeServerCount = 0
        this.otherModeServerCount = 0
        this.playerMapTreeMapData = []
        this.playerModeTreeMapData = []

        this.serverData = res['data']['data']['serverData'];
        this.personaData = res['data']['data']['personaData'];

        for (let regionIndex in this.serverData['regionEnabledServerCounts']) {
          if (BF1Region.ASIA === Number(regionIndex)) {
            this.asiaServerCount = this.serverData['regionEnabledServerCounts'][regionIndex]
          }
          else if (BF1Region.EU === Number(regionIndex)) {
            this.euServerCount = this.serverData['regionEnabledServerCounts'][regionIndex]
          }
          else if (BF1Region.SAM === Number(regionIndex) || BF1Region.NAM === Number(regionIndex)) {
            this.usServerCount += this.serverData['regionEnabledServerCounts'][regionIndex]
          }
          else {
            this.otherRegionServerCount += this.serverData['regionEnabledServerCounts'][regionIndex]
          }
        }

        for (let modeCode in this.serverData['modeEnabledServerCounts']) {
          if (modeCode === 'Conquest') {
            this.conquestServerCount = this.serverData['modeEnabledServerCounts'][modeCode]
          }
          else if (modeCode === 'BreakthroughLarge') {
            this.breakthroughLargeServerCount = this.serverData['modeEnabledServerCounts'][modeCode]
          }
          else {
            this.otherModeServerCount += this.serverData['modeEnabledServerCounts'][modeCode]
          }
        }

        this.enabledServerCount = this.serverData['totalEnabledServerCount']
        this.inGamePersonaCount = this.personaData['totalInGamePersonaCount']

        for (let mapCode in this.personaData['mapInGamePersonaCounts']) {
          this.playerMapTreeMapData.push({
            "name": Bf1Map[mapCode]['name'],
            "value": this.personaData['mapInGamePersonaCounts'][mapCode]
          })
        }
        this.initPlayerMapTreeMap()

        for (let mapCode in this.personaData['modeInGamePersonaCounts']) {
          this.playerModeTreeMapData.push({
            "name": BF1Mode[mapCode]['name'],
            "value": this.personaData['modeInGamePersonaCounts'][mapCode]
          })
        }
        this.initPlayerModeTreeMap()
      }).finally(() => {
        this.loading = false
      })
    },
    initPlayerModeTreeMap() {
      let chart = this.$echarts.init(document.getElementById("playerModeTreeMap"));
      if (!chart) {
        return
      }
      chart.setOption({
        title: {
          text: '玩家模式分布',
          left: 'center',
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {},
        series: [
          {
            name: '总览',
            type: 'treemap',
            // nodeClick: false,
            levels: [
              {
                itemStyle: {
                  borderColor: '#555',
                  borderWidth: 4,
                  gapWidth: 4
                }
              }
            ],
            data: this.playerModeTreeMapData
          }
        ]
      })
    },
    initPlayerMapTreeMap() {
      let chart = this.$echarts.init(document.getElementById("playerMapTreeMap"));
      if (!chart) {
        return
      }
      chart.setOption({
        title: {
          text: '玩家地图分布',
          left: 'center',
          textStyle: {
            color: "#fff"
          }
        },
        tooltip: {},
        series: [
          {
            name: '总览',
            type: 'treemap',
            // nodeClick: false,
            levels: [
              {
                itemStyle: {
                  borderColor: '#555',
                  borderWidth: 4,
                  gapWidth: 4
                }
              }
            ],
            data: this.playerMapTreeMapData
          }
        ]
      })
    },
    navigateToAuth(type) {
      if (type) {
        this.$router.push({
          path: "/auth",
          query: {
            type: type,
          }
        });
      } else {
        this.$router.push("/auth");
      }
    },
  }
}
</script>

<style scoped>
.index-card {
  cursor: pointer;
  background-color: rgba(17,17,17,0.62);
  color: white;
}
.index-card-container {
  &:hover {
    .index-card {
      background-color: white;
      color: #151515;
      font-weight: bold;
    }
  }
}
</style>